const GLOBAL_KEY = 'spl-field-hint-cache'

export default function createCache (key) {
    if (typeof window === 'undefined' || !key) return null

    window[GLOBAL_KEY] = window[GLOBAL_KEY] || {}

    const store = window[GLOBAL_KEY]

    const clear = () => store[key] = []
    const get = () => store[key]
    const put = (hints) => store[key] = hints

    if (!store[key]) clear()

    const any = () => Boolean(store[key]?.length > 0)

    return {
        any,
        clear,
        get,
        put,
    }
}