import styles from 'styles/Checkbox.module.css'

const Checkbox = ({ register, validations, name, label, ...rest }) => {
    return (
        <label htmlFor={name} className={styles.label}>
            <input {...register(name, validations)} {...rest}
                id={name} type="checkbox" name={name}
                className={styles.input} />

            <span className={styles.span} required={rest.required}>
                {label}
            </span>
        </label>
    )
}

export default Checkbox