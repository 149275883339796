import { useState, useEffect, useMemo } from 'react'
import Icon from '@components/shared/Icon'
import withHint from '@hoc/withHint'
import mix from '@utils/styles/mix'
import hintCache from './functions/hintCache'
import styles from 'styles/Input.module.css'

const Input = ({ register, required, validations,
    name, label, hint, unit, methods, selectlike,
    onInput, lastFormEventTime, hintMethod = withHint,
    nolabel, ...rest }) => { // <- onInput used only by NIP

    const defaultCurrentValue = methods.getValues(name) || ''

    const [, setLastClear] = useState(Date.now())
    const [currentValue, setCurrentValue] = useState(defaultCurrentValue)
    const { onChange, setValue } = methods

    // support hints and cache hints
    const cache = hintCache(hint?.cache)
    const Hint = useMemo(() => hintMethod({
        cache,
        hint,
        methods,
        name,
        selectlike,
    }), [name, hint?.url])

    const hasHint = Boolean(hint)

    // clear adv. controlled inputs on form events:
    useEffect(() => {
        if (lastFormEventTime) setCurrentValue('')
    }, [lastFormEventTime])

    // support clear
    const clear = (setValue)
        ? () => {
            if (onChange) methods.onChange({ name, value: '' })
            if (hasHint) setCurrentValue('')
            methods.setValue(name, '')
            setLastClear(Date.now())
          }
        : undefined

    // support onInput event with (external value setter)
    const usableOnInput = Boolean(onInput) // <- onInput used only by NIP, login screen support for browser autofill
        ? (e) => onInput(e, setValue)
        : undefined

    // support control schemes
    let controlProps = {}

        // support character control via regexp
        if (hint?.allowOnly) {
            controlProps = {
                value: currentValue,
                onChange: ({ target: { value }}) => {
                    const inputIsValid = !value || hint.allowOnly.test(value)

                    setCurrentValue(inputIsValid
                        ? value
                        : currentValue)
                    }
            }
        }

        // support controlled hint match
        else if (hint?.exact) {
            controlProps = {
                value: currentValue,
                onChange: ({ target: { value }}) => {
                    if (!cache.any()) return setCurrentValue('')
                    const hints = cache.get()
                    const inputIsValid = hints.some(hint => hint.toLowerCase()
                        .startsWith(value.toLowerCase()))

                    setCurrentValue(inputIsValid
                        ? value
                        : currentValue)
                    }
                }
        }

        // support list mode (select only)
        else if (hint?.select) {
            controlProps = {
                value: currentValue,
                onChange: () => {
                    setCurrentValue(currentValue)
                },
            }
        }

    const input = (
        <Actions unit={unit} clear={clear}>
            <input
                id={name}
                name={name}
                className={mix([styles.input, unit
                    ? styles.unit_input
                    : styles.action_input])}
                autoComplete="off"
                required={required}
                onInput={usableOnInput} // <- onInput used only by NIP
                onKeyUp={
                    hasHint
                        ? ({ target: {value}}) => setCurrentValue(value)
                        : undefined}
                {...register(name, validations)}
                {...rest}
                {...controlProps} />
        </Actions>
    )

    const core = hasHint
        ? <Hint currentValue={currentValue} setCurrentValue={setCurrentValue}>{input}</Hint>
        : <div className={styles.pos}>{input}</div>

    return nolabel
        ? <div className={styles.pos}>{core}</div>
        : (
            <div data-label="true">
                <label className={styles.label_text} htmlFor={name} required={required}>
                    { label || name }
                </label>
                {core}
            </div>
          )
}

function Actions ({ unit, clear, children }) {
    return (
        <span className={styles.action_wrapper}>
            { children }
            { clear && <span
                className={styles.clear_action}
                onClick={clear}>

                <Icon>close</Icon></span> }
            { unit && <span className={styles.unit}>{unit}</span> }
        </span>
    )
}

export default Input