import useT from '@hooks/useTranslation'
import styles from 'styles/RadioInput.module.css'

const RadioInput = ({ register, validations, required,
    name, methods, options, tOptions = options, lastFormEventTime,
    label: notUsedLabel, placeholder, ...rest }) => {

    return (
        <div data-label="true">
            { options.map((option, i) => {
                const id = `${name}-${option}`
                const label = useT(tOptions[i] || option)
                return (
                    <div key={id} className={styles.label}>
                        <input
                            id={id}
                            name={name}
                            type="radio"
                            value={option}
                            className={styles.radio}
                            required={required}
                            {...register(name, validations)}
                            {...rest} />

                        <label className={styles.label_text} htmlFor={id}>
                            { label }
                        </label>
                    </div>
                )
            })}
        </div>
    )
}

export default RadioInput