import styles from 'styles/Input.module.css'

const Textarea = ({ register, validations, name, label, ...rest }) => {
    return (
        <label htmlFor={name}>
            <div className={styles.label_text} required={rest.required}>{label}</div>
            <textarea id={name} name={name}
                className={styles.textarea}
                autoComplete="off"
                {...register(name, validations)} {...rest} />
        </label>
    )
}

export default Textarea