import { useState, useEffect } from 'react'
import useSWR from 'swr'
import { apiFetcherPost as apiFetcher } from '@api/apiFetcher'

import styles from 'styles/Hint.module.css'

const HINTED_TYPES = ['text', 'range']

const withHint = ({ name, type = 'text', cache, hint, methods, selectlike }) => {
    if (notEligible(type, hint)) return null
    const { url, deps = []} = hint

    return function Hint ({ children, currentValue, setCurrentValue = ()=>{} }) {
        const [focus, setFocus] = useState(false)
        const [hints, setHints] = useState([])
        const [triggerFetch, setTriggerFetch] = useState(false)

        const depValues = deps?.length > 0
            ? methods.getValues(deps)
            : null
        const depState = depValues && JSON.stringify(depValues)

        useEffect(() => {
            const blur = () => setFocus(false)
            document.addEventListener('click', blur)
            return () => document.removeEventListener('click', blur)
        }, [])

        useEffect(() => {
            if (!focus) return
            setTriggerFetch(true)
        }, [focus, depState])

        const shouldFetch = triggerFetch && focus
        const { data, error } = useSWR(
            shouldFetch
                ? hintApiUrl(url, { deps, depValues })
                : null, apiFetcher)

        if (shouldFetch && data) {
            if (cache) cache.put(data)
            setHints(data)
            setTriggerFetch(false)
        }

        if (shouldFetch && error) {
            setTriggerFetch(false)
        }

        const onClick = (e) => {
            if (!focus) document.body.click()
            e.stopPropagation()
            setFocus(!focus)
        }

        const onSelect = (e) => {
            e.stopPropagation()

            const value = e.target.getAttribute('value')

            methods.setValue(name, value)
            methods.onChange && methods.onChange({ name, value })
            if (setCurrentValue) setCurrentValue(value)
            if (focus) {
                setFocus(false)
                e.preventDefault()
            }
        }

        return (
            <div
                className={styles.wrapper}
                onClick={onClick}>

                {children}
                {focus && hints?.length > 0
                    && <Hints
                            hints={hints}
                            onSelect={onSelect}
                            currentValue={currentValue}
                            selectlike={selectlike} />}
            </div>
        )
    }
}

function Hints ({ hints = [], onSelect, currentValue = '', selectlike }) {
    if (hints.length === 0) return null

    const filtered =
        (selectlike || !currentValue)
            ? hints
            : hints.filter(hint => String(hint).toLowerCase().startsWith(currentValue.toLowerCase()))

    const items = filtered
        .map(hint => (
            <li
                key={hint}
                value={hint}
                onClick={onSelect}
                className={styles.hint}>

                    {hint}
            </li>
        ))

    return items.length > 0
        ? <HintsBox>
            {items}
          </HintsBox>

        : null
}

function HintsBox ({ children }) {
    return (
        <ul className={styles.hints}>
            {children}
        </ul>
    )
}

function notEligible (type, hint) {
    return !hint || !HINTED_TYPES.includes(type)
}

function hintApiUrl (url, depState) {
    const { query = '', isValid } = depsToQuery(depState)
    if (!isValid) return null
    return `/api/hints${url}${query}`
}

const NO_DEPS = { query: '', isValid: true }
const DEPS_NOT_FULFILLED = { query: '', isValid: false }
function depsToQuery ({ deps, depValues }) {
    if (deps?.length < 1) return NO_DEPS
    if (deps?.length !== depValues?.length) return DEPS_NOT_FULFILLED

    const query = []
    for (let i = 0; i < deps.length; i++) {
        const name = deps[i]
        const value = depValues[i]
        if (!value) {
            return DEPS_NOT_FULFILLED
        } else {
            query.push(`${name}=${value}`)
        }
    }

    return {
        query: '?' + query.join('&'),
        isValid: true,
    }
}

export default withHint
export {
    HintsBox,
    hintApiUrl,
}